import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {getImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
import Button from '../../../components/Button';
import QuestionSection from '../../../components/QuestionSection';
import {StaticImage} from 'gatsby-plugin-image';
// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import {TitleBordered, UnderlineWord, SectionSlideCard} from '../../../components/utils';

const sectionTitleClass = `mb-6 text-3xl font-bold md:text-4xl leading-[2.75rem] md:leading-[3rem] text-blue`;
const sectionTextClass = `mb-6 text-lg text-left xl:leading-loose text-black text-opacity-60`;
const backgroundTextClass = `mb-6 text-lg text-left xl:leading-loose text-white`;

const TimeboundAccessWorkflows = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top-right.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
      placeholderImage02: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      allFile(filter: {absolutePath: {regex: "/product/"}, extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        edges {
          node {
            name
            relativePath
            base
            childImageSharp {
              gatsbyImageData(width: 1140, layout: CONSTRAINED, placeholder: BLURRED, quality: 100, formats: AUTO)
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const {backgroundImage, placeholderImage02, allFile} = dataImages;

  const bgImage = convertToBgImage(getImage(backgroundImage));
  const bgImage02 = convertToBgImage(getImage(placeholderImage02));
  const imagesSource = allFile?.edges;

  return (
    <Layout seo={<Seo title="Timebound Access & Workflows | Trustle" description="Timebound Access & Workflows." />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="pt-16 pb-24 mb-8 ">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro
              hero="Product"
              title="Timebound Access & Workflows"
              description="On the scale of access to everything and access to nothing, where does your company fall?"
              isPageHeader
            />
          </div>

          <div className="mb-8 md:mb-16 text-sm w-[250px] mx-auto font-bold text-center">
            <Link to="/demo">
              <Button label="Book a Demo" variant="primary" />
            </Link>
          </div>
        </section>
      </BackgroundImage>

      <section className="relative">
        <div className="relative">
          <div className="absolute flex-row justify-end hidden w-1/2 bg-white xl:flex r text-blue -top-20 filter drop-shadow-2xl ">
            <div className={`${sectionTitleClass} text-left max-w-[550px] pt-14 pb-16 `}>
              Trustle offers a wide range of features to <UnderlineWord word="decrease" /> over-entitlement and{' '}
              <UnderlineWord word="increase" /> productivity.
            </div>
          </div>
        </div>
        <div className="mb-20 lg:mb-32">
          <div className="px-0 pt-0 mx-auto text-center text-white md:mb-20 max-w-7xl">
            <div className="flex flex-col justify-between xl:flex-row-reverse">
              <div className="flex flex-col items-start max-w-5xl mr-5 xl:hidden text-blue">
                <div
                  className={`${sectionTitleClass} text-left w-full filter drop-shadow-2xl rounded-r-lg bg-white px-5 py-10 `}
                >
                  Trustle offers a wide range of features to <UnderlineWord word="decrease" /> over-entitlement and{' '}
                  <UnderlineWord word="increase" /> productivity.
                </div>
              </div>

              <div className="flex flex-col items-start max-w-xl px-5 text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Delegated Authority</div>

                <p className={sectionTextClass}>
                  Trustle helps accelerate access reviews by allowing you to delegate the authority to trusted
                  individuals within your organization who have the necessary context to approve or deny access. This
                  empowers your access management solution to scale, while simultaneously empowering your employees to
                  be more productive.
                </p>
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto text-center text-white md:mb-20 max-w-7xl">
            <div className="flex flex-col justify-between lg:flex-row">
              <div className="flex flex-col items-start max-w-[550px] text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Just-in-Time Access</div>

                <p className={sectionTextClass}>
                  Trustle enforces zero standing privilege until least privileged, JIT access is required, ensuring your
                  users only have the minimal access they need, when they actually need it. This helps minimize your
                  attack surface, and reduces the risk of unauthorized access and data breaches.
                </p>
              </div>
              <div className="flex flex-col items-start max-w-[550px] text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Automation Orchestration</div>

                <p className={sectionTextClass}>
                  Trustle offers automation capabilities that allow you to automate routine tasks and workflows based on
                  resource classifications, as well as previous access approval  history. This saves valuable time and
                  boosts productivity for your security teams, system owners, and access requestors.
                </p>
              </div>
            </div>
          </div>

          <div className="px-5 pt-0 mx-auto text-center text-white md:mb-20 max-w-7xl">
            <div className="flex flex-col justify-between lg:flex-row">
              <div className="flex flex-col items-start max-w-[550px] text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">On-Call Access</div>

                <p className={sectionTextClass}>
                  Trustle automates on-call access, ensuring that engineers have the necessary access to resources
                  during incidents. This reduces incident-related downtime, and keeps your on-call team at zero standing
                  privilege until least privilege access is needed.
                </p>
              </div>
              <div className="flex flex-col items-start max-w-[550px] text-blue">
                <div className="mb-4 text-xl font-bold md:text-2xl">Single Click Remediation</div>

                <p className={sectionTextClass}>
                  Trustle simplifies the process of revoking access to resources when an employee or contractor leaves
                  your organization. This reduces the risk of unauthorized access and ensures the security of your data.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#01093C] mb-20 lg:mb-32">
          <SectionSlideCard title="Connect, compute, cleanup" imagesSource={imagesSource} />
        </div>
        <div className="px-5 pt-0 mx-auto mb-20 text-center text-blue max-w-8xl">
          <TitleBordered title="Containing Permissions Sprawl with Trustle" />
          <div className="max-w-4xl mx-auto mb-4 text-xl font-bold text-center md:text-2xl">
            When permissions sprawl presents itself, it must be managed effectively. 
          </div>
        </div>

        <div className="px-5 pt-0 mx-auto mb-20 text-center text-white max-w-[1360px]">
          <div className="flex flex-col justify-between lg:flex-row">
            <div className="flex flex-col items-start text-blue">
              <div className="max-w-md xl:max-w-xl">
                <StaticImage
                  src="../../../images/product/product-certification-classification-box.jpg"
                  alt="trustle app"
                  className="mb-8"
                />

                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Certification and Classification </div>
                <p className={sectionTextClass}>
                  <span className="font-bold text-blue">
                    As companies grow, how they manage their access must scale and evolve as well.
                  </span>
                </p>
                <p className={sectionTextClass}>
                  Where a non-secure, simple, one-size fits all approach may have worked in the beginning (spreadsheets
                  sound familiar?), certifications compliance introduces a new layer of complexity to the process.
                </p>
                <p className={sectionTextClass}>
                  <span className="font-bold text-blue">
                    Resources now need to be classified and Roles need to be defined.
                  </span>
                </p>
                <p className={sectionTextClass}>
                  Employees, contractors, customers, and service accounts may all require unique approval workflows
                  based on the sensitivity of the resource they are requesting access to.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start text-blue">
              <div className="max-w-md xl:max-w-xl">
                <StaticImage
                  src="../../../images/product/product-internal-tools-box.jpg"
                  alt="trustle app"
                  className="mb-8"
                />

                <div className="mb-8 text-xl font-bold text-left md:text-2xl">
                  Internal Tools and Sprawling Applications
                </div>
                <p className={sectionTextClass}>
                  <span className="font-bold text-blue">Some companies develop internal, admin tools</span> to try and
                  support their growing business for compliance requirements. On one hand these internal systems may
                  provide carte blanche access to internal, sensitive and non-sensitive data as requested, however on
                  the other hand, they implement a rigid process which severely limits access and impacts productivity.{' '}
                  <br></br>
                  <br></br>
                  <span className="font-bold text-blue">
                    Modern enterprises have started to adopt several cloud-based applications,
                  </span>{' '}
                  each with its own unique feature set and design platform. Maintaining the sprawl of these internal
                  tools and the multitude of applications becomes a project all of its own.
                </p>

                <TitleBordered title="Let Trustle handle that project for you." align="left" fontSizeClass="text-2xl" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage02}
        preserveStackingContext
        className="bg-top bg-cover"
      >
        <section>
          <div className="px-5 pt-20 mx-auto  text-center text-white max-w-[1360px] ">
            <div className="flex flex-col items-center justify-between pb-16 xl:items-baseline xl: xl:flex-row">
              <div className="flex flex-col md:max-w-[400px] items-center mb-8  xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Native API Integration</div>
                <p className={backgroundTextClass}>
                  Using native APIs, Trustle ingests account, permission, resource, and usage data from each of your
                  cloud and SaaS systems. Using Trustle's machine learning technology, We provide deeper end-to-end
                  visibility and control to each of your integrated systems.
                </p>
                <p className={backgroundTextClass}>
                  <b>
                    Trustle empowers your team to make context based decisions providing compliant, automated access for
                    the right user, to the right resource, for the right length of time.
                  </b>
                </p>
              </div>

              <div className="flex flex-col md:max-w-[400px] items-center mb-8 xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Advanced Workflows</div>
                <p className={backgroundTextClass}>
                  Trustle offers an advanced, workflow solution that helps you efficiently and securely manage access to
                  your cloud environment. You can set up Trustle's workflow to enforce your approval chain requirements
                  and provide time-based access depending on the sensitivity of the resources being accessed.
                </p>
                <p className={backgroundTextClass}>
                  <b>You will know exactly who has access to what, And can trust your data is secure.</b>
                </p>
              </div>

              <div className="flex flex-col md:max-w-[400px] items-center mb-8 xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Fine-Grained Access Control</div>
                <p className={backgroundTextClass}>
                  Trustle allows you to effortlessly scale permissions management in your enterprise by enforcing zero
                  standing privilege until fine-grained, least privilege access is needed. This minimizes your attack
                  surface by reducing the risk of data breaches.
                </p>
                <p className={backgroundTextClass}>
                  <b>
                    Your workflows don't need to change, but your expectations around them being embraced and adopted
                    will.
                  </b>
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <section>
        <QuestionSection />
      </section>
    </Layout>
  );
};
export default TimeboundAccessWorkflows;
